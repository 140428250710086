import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

const MasterTindakanLain = () => {
  const apiku = sessionStorage.getItem("api");
  //   const apiku = "http://182.168.7.251:5577";
  const token = sessionStorage.getItem("userData");
  const options = {
    headers: { Authorization: "Bearer " + token },
  };
  const [listpenunjanglain, setPenunjanglain] = useState([]);
  const [idPenunjang, setIdPenunjang] = useState(null);
  const [deskripi, setDeskripsi] = useState(null);
  const [idHasil, setHasil] = useState(null);
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const ambilListPenunjangLain = () => {
    axios
      .get(`${apiku}/MstPenunjangLain/Lookup/%20`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setPenunjanglain(res.data.result);
        } else {
          setPenunjanglain([]);
        }
      })
      .catch((err) => {
        setPenunjanglain([]);
      });
  };
  const insertPenunjangLain = () => {
    axios
      .post(`${apiku}/MstPenunjangLain/`, dataPenunjang, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success("Berhasil!");
          ambilListPenunjangLain();
        } else {
          message.warning("Gagal!" + " - " + res.data.message);
        }
      })
      .catch((err) => {
        message.error("Terjadi Kesalahan!");
      });
  };
  const deletePenunjangLain = (id) => {
    axios
      .delete(`${apiku}/MstPenunjangLain/PenunjangLain/${id}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success("Berhasil hapus!");
          ambilListPenunjangLain();
        } else {
          message.warning("Gagal!" + " - " + res.data.message);
        }
      })
      .catch((err) => {
        message.error("Terjadi Kesalahan!");
      });
  };
  const columns = [
    {
      title: "No",
      key: "reg",
      className: "tabeltabel2",
      width: "5%",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "ID",
      dataIndex: "hasilPenunjangId",
    },
    {
      title: "Deskripsi Tindakan",
      dataIndex: "deskripsiHasilPenunjang",
    },
    {
      title: "Aksi",
      render: (text, record, index) => (
        <>
          <Popconfirm
            title="Hapus data"
            description="Apakah anda yakin menghapus data?"
            onConfirm={() => deletePenunjangLain(record.hasilPenunjangId)}
            onCancel={() => console.log("e")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger size="small">
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  useEffect(() => {
    ambilListPenunjangLain();
  }, []);
  const dataPenunjang = {
    hasilPenunjangId: idPenunjang,
    deskripsiHasilPenunjang: deskripi,
    hasilId: idHasil,
    clientHost: " ",
    dateEntry: "2023-05-15T00:00:00.000Z",
    clientIP: " ",
  };
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Table
            size="small"
            pagination={false}
            scroll={{ y: 470 }}
            dataSource={listpenunjanglain}
            columns={columns}
          />
        </Col>
        <Col span={12}>
          <Card size="small" title="Tambah Penunjang Lain">
            <Form {...formItemLayout}>
              <Form.Item label="Id">
                <Input onChange={(e) => setIdPenunjang(e.target.value)} />
              </Form.Item>
              <Form.Item label="Nama Tindakan Penunjang">
                <Input onChange={(e) => setDeskripsi(e.target.value)} />
              </Form.Item>
              <Form.Item label="Kode Tindakan Penunjang">
                <Input onChange={(e) => setHasil(e.target.value)} />
              </Form.Item>
              <Button
                block
                type="primary"
                onClick={() => {
                  console.log(dataPenunjang);
                  insertPenunjangLain(dataPenunjang);
                }}
              >
                Tambahkan
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MasterTindakanLain;
