import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  Empty,
  Table,
  Tabs,
  Row,
  Col,
  Typography,
  Space,
  Tree,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import Iframe from "react-iframe";
import ButtonPRMRJ from "../rawatjalan/komponen/ButtonPRMRJ";
import { PrintOutContext } from "../PrintOutDokumen/PrintOutContext";
import { HasilLabContext } from "../rawatjalan/context/HasilLabContext";
import { HasilRadiologiContext } from "../rawatjalan/context/HasilRadiologiContext";
import { PasienContext } from "../rawatjalan/context/PasienContext";
import RiwayatOrder from "../rawatjalan/orderresep/RiwayatOrder";
import ButtonRingkasanRJ from "./ButtonRingkasanRJ";
import DropdownLaborat from "../rawatjalan/komponen/DropdownLaborat";
import FormLaporanKala from "../rawatinap/pemeriksaanharian/FormLaporanKala";
const { TabPane } = Tabs;
const { Column } = Table;
const { Text, Title } = Typography;

const RiwayatPemeriksaan = () => {
  const {
    riwayatpenyakit,
    riwayatpemeriksaan,
    riwayatpasien,
    detailRiwayatPenyakit,
    detailRiwayatPemeriksaan,
    pasienid,
    detailPasien,
    loading,
  } = useContext(PasienContext);
  const namauser = sessionStorage.getItem("userId");
  const [viewaskep, setviewaskep] = useState("");
  const namaPasien = sessionStorage.getItem("namaPasienRawat");
  const [tampil, setTampil] = useState(false);
  const [riwayat, setRiwayat] = useState(true);
  const { GetHasilRadiologiByNoreg, hasilRisByNoreg } = useContext(
    HasilRadiologiContext
  );
  const {
    getListOpByPasienIdnNOreg,
    listOpinNoreg,
    getListLabByNOreg,
    listPkbyNoreg,
  } = useContext(HasilLabContext);
  const {
    getPrintRm02,
    getPrintRm13,
    printRm02,
    printRm13,
    getPrintHasilOp,
    printHasilOp,
    setprintHasilOp,
    getPrintLabPk,
    printLabPk,
    setprintLabPk,
    getPrintTerimaPasien,
    getPrintAnamnesa,
    getPrintfisikRI,
    getPrintAssAske,
    getPrintRM11,
    getPrintAsuhan,
    getPrintKonsulRI,
    getPrintCatatanPerawat,

    printRm02Kunjungan,
    printCPPTRI,
    printDokterIGD,
    printPerawatIGD,
    printAnamnesa,
    printFirikRI,
    printAss,
    printRM11,
    printasuhan,
    printKonsulRI,
    printSBarPerawat,
    getPrintRm02Kunjungan,
    getPrintCPPTRI,
    getPrintDokterIGD,
    getPrintPerawatIGD,
  } = useContext(PrintOutContext);
  const [URLNoreg, setURLNoreg] = useState("");

  const onKembali = () => {
    setRiwayat(true);
    setTampil(false);
    setURLNoreg("");
    setprintLabPk("");
    setprintHasilOp("");
    setviewaskep("");
  };
  const onAmbilRiwayat = (e) => {
    setviewaskep("http://182.168.0.119:8082/#/" + e + "/" + namauser);
    detailRiwayatPenyakit(e);
    detailRiwayatPemeriksaan(e);
    setTampil(true);
    setRiwayat(false);
    getListOpByPasienIdnNOreg(e);
    getListLabByNOreg(pasienid, e);
    GetHasilRadiologiByNoreg(pasienid, e);
    getPrintRm02Kunjungan(pasienid, e);
    getPrintRm13(pasienid, e);
    detailPasien(e);
    getPrintTerimaPasien(e);
    getPrintAnamnesa(e);
    getPrintfisikRI(e);
    getPrintAssAske(e, "1");
    getPrintRM11(e);
    getPrintAsuhan(e);
    getPrintKonsulRI(e);
    getPrintCatatanPerawat(e);
    getPrintDokterIGD(e);
    getPrintPerawatIGD(e);
  };
  return (
    <div>
      <Card
        style={{ marginTop: 5 }}
        size="small"
        title="Riwayat Pasien"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        extra={
          <Space>
            <DropdownLaborat />
            <ButtonRingkasanRJ />
            <ButtonPRMRJ />
          </Space>
        }
      >
        {riwayat ? (
          <Table
            loading={loading}
            dataSource={riwayatpasien}
            size="small"
            bordered
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"Silahkan Pilih Pasien Terlebih Dahulu"}
                />
              ),
            }}
          >
            <Column
              title="Nomor Registrasi"
              key="reg"
              dataIndex="RegistrasiId"
              width="90px"
              className="bgcolor fontkecil"
            />
            <Column
              title="Tanggal Masuk RS"
              dataIndex="TanggalMasuk"
              width="80px"
              key="pasienId"
              className="fontkecil"
            />
            {/* <Column
              title="Ruang"
              dataIndex="RuangDeskripsi"
              width="80px"
              key="pasienId"
              className="fontkecil"
            />
            <Column
              title="Kode ICD"
              dataIndex="DiagnosisId"
              width="80px"
              key="pasienId"
              className="fontkecil"
            />
            <Column
              title="Penyakit"
              dataIndex="Deskripsi"
              width="200px"
              key="pasienId"
              className="fontkecil"
            /> */}
            <Column
              title="Action"
              width="60px"
              className="fontkecil"
              render={(riwayatpasien) => (
                <span>
                  <Button
                    size="small"
                    type="primary"
                    onClick={(e) => onAmbilRiwayat(riwayatpasien.RegistrasiId)}
                  >
                    Detail
                  </Button>
                </span>
              )}
            />
          </Table>
        ) : null}
        {tampil === true ? (
          <Tabs
            size="small"
            type="card"
            defaultActiveKey="1"
            tabBarExtraContent={
              <Button size="small" type="primary" danger onClick={onKembali}>
                Kembali
              </Button>
            }
          >
            <TabPane tab="Penyakit" key="1">
              <Table
                dataSource={riwayatpenyakit}
                size="small"
                rowKey="reg"
                scroll={{ y: 470 }}
                bordered
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={"Silahkan Pilih Pasien Terlebih Dahulu"}
                    />
                  ),
                }}
              >
                <Column
                  title="Reg"
                  key="reg"
                  dataIndex="RegistrasiId"
                  width="90px"
                  className="bgcolor fontkecil"
                />
                <Column
                  title="Tanggal"
                  dataIndex="TanggalMasuk"
                  width="80px"
                  key="pasienId"
                  className="fontkecil"
                />
                <Column
                  title="Kode ICD"
                  dataIndex="DiagnosaId"
                  width="80px"
                  key="pasienId"
                  className="fontkecil"
                />
                <Column
                  title="Penyakit"
                  dataIndex="DiagnosaDesk"
                  width="300px"
                  key="pasienId"
                  className="fontkecil"
                />
                <Column
                  title="Kasus"
                  dataIndex="KasusIcd"
                  width="50px"
                  key="pasienId"
                  className="fontkecil"
                />
              </Table>
            </TabPane>
            <TabPane tab="Resep Pasien" key="2">
              <RiwayatOrder />
            </TabPane>
            <TabPane tab="Hasil Lab" key="3">
              <Row>
                <Col span={24}>
                  {/* <Title level={4}>Data RM 02 Pasien</Title> */}
                  <Text keyboard>Data Hasil Laborat Pasien</Text>
                </Col>
                <Col span={3}>
                  <Space direction="vertical">
                    <Title
                      level={5}
                      style={{ marginBottom: "0", marginTop: "0" }}
                    >
                      {namaPasien}
                    </Title>
                    <Text code style={{ marginBottom: "0", marginTop: "0" }}>
                      {pasienid}
                    </Text>
                    <Tree
                      showLine
                      switcherIcon={<DownOutlined />}
                      onSelect={(e) => {
                        e[0].search("LI") >= 0
                          ? getPrintLabPk(pasienid, e[0])
                          : getPrintLabPk(
                              pasienid,
                              listPkbyNoreg[
                                listPkbyNoreg
                                  .map((c) => c.registrasiId)
                                  .indexOf(e[0])
                              ].listNoLab[
                                listPkbyNoreg[
                                  listPkbyNoreg
                                    .map((c) => c.registrasiId)
                                    .indexOf(e[0])
                                ].listNoLab.length - 1
                              ].labNomor
                            );
                      }}
                      treeData={listPkbyNoreg.map((b) => ({
                        title: b.registrasiId,
                        key: b.registrasiId,
                        children: b.listNoLab.map((c) => ({
                          title: c.labNomor,
                          key: c.labNomor,
                        })),
                      }))}
                    />
                  </Space>
                </Col>
                <Col span={21}>
                  <Iframe
                    url={printLabPk}
                    width="100%"
                    height="750px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Hasil Radiologi" key="5">
              <Row>
                <Col span={24}>
                  {/* <Title level={4}>Data RM 02 Pasien</Title> */}
                  <Text keyboard>Data Hasil Radiologi Pasien</Text>
                </Col>
                <Col span={3}>
                  <Space direction="vertical">
                    <Title
                      level={5}
                      style={{ marginBottom: "0", marginTop: "0" }}
                    >
                      {namaPasien}
                    </Title>
                    <Text code style={{ marginBottom: "0", marginTop: "0" }}>
                      {pasienid}
                    </Text>
                    <Tree
                      showLine
                      switcherIcon={<DownOutlined />}
                      onSelect={(e) => {
                        // console.log(e[0].length === 10 ? 'benar' : 'salah')
                        e[0].length === 10
                          ? setURLNoreg(
                              hasilRisByNoreg[
                                hasilRisByNoreg
                                  .map((c) => c.registrasiId)
                                  .indexOf(e[0])
                              ].listLink[0].urlExpertise
                            )
                          : setURLNoreg(e[0]);
                      }}
                      treeData={hasilRisByNoreg.map((b) => ({
                        title: b.registrasiId,
                        key: b.registrasiId,
                        children: b.listLink.map((c) => ({
                          title: c.fotoNumber,
                          key: c.urlExpertise,
                        })),
                      }))}
                    />
                  </Space>
                </Col>
                <Col span={21}>
                  <Iframe
                    url={URLNoreg}
                    width="100%"
                    height="750px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Pemeriksaan" key="6">
              <Table
                dataSource={riwayatpemeriksaan}
                size="small"
                rowKey="reg"
                scroll={{ y: 470 }}
                bordered
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={"Silahkan Pilih Pasien Terlebih Dahulu"}
                    />
                  ),
                }}
              >
                <Column
                  title="Tanggal"
                  dataIndex="tanggalPemeriksaan"
                  width="80px"
                  key="pasienId"
                  className="fontkecil"
                />
                <Column
                  title="Bagian"
                  dataIndex="ruangDesk"
                  key="pasienId"
                  className="fontkecil"
                />
                <Column
                  title="Jenis Pemeriksaan"
                  dataIndex="pelayananDesk"
                  width="300px"
                  key="pasienId"
                  className="fontkecil"
                />
              </Table>
            </TabPane>

            <TabPane tab="RM02" key="8">
              <Iframe
                url={printRm02Kunjungan}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="CPPT" key="12">
              <Iframe
                url={printCPPTRI}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="RM01 IGD" key="13">
              <Tabs size="small" type="card" defaultActiveKey="1">
                <TabPane tab="Catatan Dokter IGD" key="1a">
                  {" "}
                  <Iframe
                    url={printDokterIGD}
                    width="100%"
                    height="750px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                </TabPane>
                <TabPane tab="Catatan Perawat IGD" key="1b">
                  {" "}
                  <Iframe
                    url={printPerawatIGD}
                    width="100%"
                    height="750px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                </TabPane>
              </Tabs>
            </TabPane>

            <TabPane tab="Anamnesa RI" key="14">
              <Iframe
                url={printAnamnesa}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="Pem. Fisik RI" key="15">
              <Iframe
                url={printFirikRI}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="Assesment Askep RI" key="16">
              <Iframe
                url={printAss}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="RM 11" key="17">
              <Iframe
                url={printRM11}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="Askep RI" key="18">
              <Iframe
                url={printasuhan}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="Konsul RI" key="19">
              <Iframe
                url={printKonsulRI}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="RM 13" key="20">
              <Iframe
                url={printRm13}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
            <TabPane tab="Laporan OP" key="7">
              <Row>
                <Col span={24}>
                  {/* <Title level={4}>Data RM 02 Pasien</Title> */}
                  <Text keyboard>Data Hasil Operasi Pasien</Text>
                </Col>
                <Col span={3}>
                  <Space direction="vertical">
                    <Title
                      level={5}
                      style={{ marginBottom: "0", marginTop: "0" }}
                    >
                      {namaPasien}
                    </Title>
                    <Text code style={{ marginBottom: "0", marginTop: "0" }}>
                      {pasienid}
                    </Text>
                    <Tree
                      showLine
                      switcherIcon={<DownOutlined />}
                      onSelect={(e) => {
                        getPrintHasilOp(pasienid, e[0]);
                      }}
                      treeData={listOpinNoreg.map((b) => ({
                        title: b.registrasiId,
                        key: b.registrasiId,
                        children: b.listLink.map((c) => ({
                          title: c.laporanOperasiId,
                          key: c.laporanOperasiId,
                        })),
                      }))}
                    />
                  </Space>
                </Col>
                <Col span={21}>
                  <Iframe
                    url={printHasilOp}
                    width="100%"
                    height="750px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Lap Persalinan" key="21">
              <FormLaporanKala />
            </TabPane>
            <TabPane tab="Catatan Perawat" key="22">
              <Iframe
                url={printSBarPerawat}
                width="100%"
                height="750px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </TabPane>
          </Tabs>
        ) : null}
      </Card>
      {/* </Drawer> */}
    </div>
  );
};

export default RiwayatPemeriksaan;
